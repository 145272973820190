<template>
  <div
    :class="noAlign ? '' : 'd-flex flex-row align-content-center align-items-center'"
  >
    <span
      :class="classIcon + ' ' + pointer"
      @click="$emit('on:add-link-click')"
    >
      <b-title class="label-text">
        {{ $t(textLink) }}
      </b-title>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    textLink: {
      type: String,
      default: ''
    },
    noAlign: {
      type: Boolean,
      default: false
    },
    pointer: {
      type: String,
      default: 'pointer'
    },
    classIcon: {
      type: String,
      default: ''
    },
  }
}
</script>
<style lang="scss" scoped>
.label-text {
  font-family: Avenir, sans-serif;
}
</style>
